import React from 'react';

type Props = {
  className?: string;
}

const Icon: React.FC<Props> = ({ className }: Props) =>
  <svg className={className} width="40" height="24" viewBox="0 0 40 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.72309 22.213C4.12818 22.2184 3.53811 22.1017 2.9868 21.8697C2.43549 21.6378 1.93379 21.295 1.51055 20.8613C1.22443 20.573 0.996917 20.2282 0.841461 19.8471C0.686004 19.4661 0.605759 19.0566 0.605469 18.6428L0.814333 4.00083C0.834225 2.08159 2.70406 0.533813 4.96179 0.533813L35.277 0.925917C36.4765 0.915745 37.6313 1.39792 38.4895 2.26732C38.7757 2.55558 39.0032 2.90043 39.1586 3.28147C39.3141 3.66252 39.3943 4.07203 39.3946 4.4858L39.1858 19.1381C39.1858 21.047 37.296 22.6051 35.0383 22.6051L4.72309 22.213Z" fill="white" />
    <path d="M4.96177 1.0498H5.02145L35.277 1.4419C36.3421 1.43138 37.3681 1.85788 38.1315 2.62853C38.37 2.86894 38.5605 3.15586 38.6919 3.47281C38.8233 3.78977 38.8931 4.13053 38.8973 4.47554L38.6884 19.1278C38.6884 20.7581 37.0275 22.0892 35.0383 22.0892H34.9786L4.72307 21.6868C3.65796 21.6973 2.63199 21.2708 1.86859 20.5002C1.63003 20.2598 1.43957 19.9729 1.30815 19.6559C1.17673 19.3389 1.10693 18.9982 1.10275 18.6532L1.31161 4.00089C1.3315 2.37056 2.97258 1.0498 4.96177 1.0498ZM4.96177 0.0179443C2.42556 0.0179443 0.346857 1.79273 0.317019 3.99057L0.108154 18.6429C0.108154 20.8613 2.14707 22.7187 4.71312 22.7187L34.9687 23.1108H35.0383C37.5745 23.1108 39.6532 21.336 39.683 19.1381L39.8919 4.49618C39.8919 2.2777 37.9027 0.441003 35.2869 0.368774L5.08112 0.0179443H4.96177Z" fill="#CCCCCC" />
    <path d="M17.2321 6.23417L17.0948 16.8303L22.7833 16.9096L22.9206 6.31349L17.2321 6.23417Z" fill="#7369AC" />
    <path d="M17.5136 11.5334C17.5261 10.5124 17.7628 9.50772 18.2057 8.59585C18.6486 7.68398 19.2861 6.88886 20.0697 6.27094C19.1218 5.4767 17.9777 4.97509 16.7681 4.82345C15.5584 4.6718 14.3321 4.87623 13.2291 5.41337C12.1262 5.95051 11.1911 6.7987 10.5307 7.86103C9.8704 8.92336 9.51141 10.157 9.49481 11.4209C9.4782 12.6849 9.80464 13.9282 10.4368 15.0088C11.069 16.0894 11.9814 16.9637 13.0699 17.5319C14.1583 18.1 15.3788 18.339 16.592 18.2216C17.8052 18.1043 18.962 17.6352 19.9304 16.8681C19.1665 16.225 18.5524 15.4112 18.1339 14.4875C17.7154 13.5638 17.5034 12.554 17.5136 11.5334Z" fill="#D01D17" />
    <path d="M30.5129 11.7088C30.4952 12.9732 30.1355 14.207 29.4747 15.2696C28.8139 16.3322 27.8786 17.1808 26.7754 17.7186C25.6722 18.2564 24.4456 18.4619 23.2354 18.3115C22.0251 18.1611 20.8799 17.6609 19.9304 16.868C20.714 16.2498 21.3517 15.4545 21.795 14.5426C22.2384 13.6306 22.4757 12.6259 22.4892 11.6047C22.5026 10.5834 22.2917 9.57237 21.8725 8.64823C21.4533 7.72409 20.8368 6.9111 20.0697 6.27091C21.0374 5.50195 22.1942 5.03106 23.4078 4.91209C24.6213 4.79312 25.8427 5.03087 26.9321 5.59815C28.0216 6.16543 28.9351 7.03935 29.5684 8.11995C30.2016 9.20056 30.5289 10.4442 30.5129 11.7088Z" fill="#2D96D3" />
  </svg>

export default Icon;
