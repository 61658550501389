import { HTMLAttributes } from 'react';
import clsx from 'clsx';
import { MdArrowForward, MdOutlineCheckCircle } from 'react-icons/md';
import { useFormat } from '~/helpers/hooks/useFormat';

interface MobileMegamenuNewsletterCtaProps extends HTMLAttributes<HTMLButtonElement> {
  onClick(): void;
}

export default function MobileMegamenuNewsletterCta({
  className,
  onClick,
  ...props
}: MobileMegamenuNewsletterCtaProps): JSX.Element {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  function handleClick() {
    onClick();

    setTimeout(() => {
      // Use native browser instead of router.push because CSS smooth-scroll doesn't integrate well
      location.hash = '';
      location.hash = 'newsletter';
    });
  }

  return (
    <button
      className={clsx(
        'surface-grey-1 flex items-center gap-x-2 border-t border-solid border-grey-3 px-5 py-6 text-left',
        className,
      )}
      type="button"
      onClick={() => handleClick()}
      {...props}
    >
      <MdOutlineCheckCircle className="text-24" />

      <p className="flex-1 font-bold">
        {formatMessage({ id: 'components.mobile-nav-drawer-newsletter-cta.subscribe_to_newsletter' })}
      </p>

      <MdArrowForward className="text-24" />
    </button>
  );
}
