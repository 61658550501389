import { useContext, useId, useState } from 'react';
import { usePathname, useRouter } from 'next/navigation';
import clsx from 'clsx';
import { MdSearch } from 'react-icons/md';
import { ProgressIndicatorContext } from '~/helpers/contexts/furniturechoice/progress-indicator';
import { useFormat } from '~/helpers/hooks/useFormat';

export default function SearchField(): JSX.Element {
  const id = useId();
  const router = useRouter();
  const pathname = usePathname();
  const isSearchPage = pathname.includes('/search');
  const pathSegments = pathname.split('/');
  const pathSearchTerm = isSearchPage && pathSegments.length > 3 ? pathSegments[3] : '';
  const sanitizedSearchTerm = decodeURIComponent(pathSearchTerm);

  const { formatMessage } = useFormat({ name: 'furniturechoice' });
  const { navigateWithProgress } = useContext(ProgressIndicatorContext);
  const [searchTerm, setSearchTerm] = useState(sanitizedSearchTerm);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      navigateWithProgress(async () => {
        const encodedSearchTerm = encodeURIComponent(searchTerm);
        router.push(`/search/${encodedSearchTerm}`);
      });
    }
  };

  return (
    <div className="flex h-10 w-full items-center rounded-full border border-solid border-grey-4 px-2.5 [grid-area:search] lg:h-[30px]">
      <label className="sr-only" htmlFor={id}>
        {formatMessage({ id: 'tastics.core.site-header.what_are_you_looking_for?' })}
      </label>

      <MdSearch className="my-2.5 text-20" />

      <input
        className={clsx(
          'h-full w-full appearance-none bg-transparent py-2.5 pl-1 text-14 placeholder:text-grey-4',
          // eslint-disable-next-line prettier/prettier
          'outline-none [-moz-appearance:none] [-webkit-appearance:none] focus:outline-none',
        )}
        data-testid="search-field-input"
        autoComplete="off"
        value={searchTerm}
        onChange={(event) => setSearchTerm(event.target.value)}
        type="search"
        placeholder={formatMessage({ id: 'tastics.core.site-header.what_are_you_looking_for?' })}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
}
