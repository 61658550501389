import { useState, useEffect } from 'react';

interface QuantitySelectorProps {
  quantity: number;
  minValue?: number;
  maxValue?: number;
  onCountUpdate: (newQuantity: number) => void;
  isSwatch?: boolean;
}

export default function QuantitySelector({
  quantity,
  minValue,
  maxValue,
  onCountUpdate,
  isSwatch,
}: QuantitySelectorProps): JSX.Element {
  const [selectorQty, setSelectorQty] = useState<number>(quantity);

  useEffect(() => {
    if (isSwatch) {
      setSelectorQty(maxValue ?? 1);
    }
  }, [isSwatch, maxValue]);

  function decreaseAction() {
    if (minValue !== undefined && selectorQty > minValue) {
      setSelectorQty(selectorQty - 1);
      onCountUpdate(selectorQty - 1);
    } else if (minValue === undefined) {
      setSelectorQty(selectorQty - 1);
      onCountUpdate(selectorQty - 1);
    }
  }

  function increaseAction() {
    if (maxValue !== undefined && selectorQty < maxValue) {
      setSelectorQty(selectorQty + 1);
      onCountUpdate(selectorQty + 1);
    } else if (maxValue === undefined) {
      setSelectorQty(selectorQty + 1);
      onCountUpdate(selectorQty + 1);
    }
  }

  return (
    <div
      data-testid="quantitySelector"
      className="my-1 flex min-h-[32px] w-20 justify-evenly rounded border border-grey-4 px-1 text-grey-5"
    >
      {isSwatch ? (
        <span className="cursor-default self-center px-2 text-14 font-semibold leading-tight">{selectorQty}</span>
      ) : (
        <>
          <button type="button" onClick={decreaseAction}>
            -
          </button>
          <span className="cursor-default self-center px-2 text-14 font-semibold leading-tight">{selectorQty}</span>
          <button type="button" onClick={increaseAction}>
            +
          </button>
        </>
      )}
    </div>
  );
}
