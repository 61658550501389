import React from 'react';

type Props = {
  className?: string;
}

const Icon: React.FC<Props> = ({ className }: Props) =>
  <svg className={className} width="40" height="23" viewBox="0 0 40 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6064_15964)">
      <path d="M4.70879 22.213C3.5018 22.2263 2.33783 21.7484 1.46642 20.8819C1.17969 20.5957 0.951034 20.2529 0.793892 19.8737C0.63675 19.4945 0.5543 19.0865 0.551392 18.6737L0.650851 4.02147C0.670743 2.08159 2.54058 0.533813 4.82815 0.533813L35.1831 0.729865C36.3929 0.722101 37.5573 1.20751 38.4255 2.08159C38.7122 2.36777 38.9409 2.71055 39.098 3.08977C39.2552 3.46899 39.3376 3.87698 39.3405 4.28975L39.2411 18.942C39.2411 20.8613 37.3514 22.4297 35.0638 22.4297L4.70879 22.213Z" fill="white" />
      <path d="M4.82813 0.0179443V1.04979L35.1533 1.24585C35.685 1.2352 36.2136 1.33363 36.7086 1.5355C37.2035 1.73736 37.6552 2.03868 38.0376 2.42215C38.2852 2.65762 38.4842 2.94267 38.6226 3.26011C38.7611 3.57756 38.8361 3.92081 38.8432 4.26917L38.7438 18.9214C38.7438 20.5621 37.0828 21.8932 35.0339 21.8932L4.70878 21.6868C4.17703 21.6974 3.64849 21.599 3.15351 21.3971C2.65853 21.1953 2.20687 20.894 1.82445 20.5105C1.58351 20.2736 1.39073 19.989 1.2575 19.6735C1.12426 19.3581 1.05325 19.0182 1.04867 18.6738L1.14813 4.02152C1.16802 2.38088 2.8091 1.04979 4.82813 1.04979V0.0179443ZM4.82813 0.0179443C2.27202 0.0179443 0.173428 1.81336 0.153537 4.02152L0.0540771 18.6738C0.0540771 20.8923 2.13278 22.7186 4.69883 22.7186L35.0339 22.9147C37.59 22.9147 39.6886 21.1193 39.7085 18.9111L39.8378 4.26917C39.8378 2.08164 37.7591 0.224314 35.1931 0.213996L4.85796 0.0179443H4.82813Z" fill="#CCCCCC" />
      <path d="M17.2066 6.27675L17.1404 16.626L22.6902 16.6642L22.7563 6.31494L17.2066 6.27675Z" fill="#FF5F00" />
      <path d="M17.5192 11.4508C17.5286 10.4557 17.7533 9.4754 18.1768 8.58242C18.6003 7.68945 19.2117 6.90656 19.9659 6.29156C18.8589 5.37307 17.4845 4.87109 16.0671 4.8676C15.0555 4.86355 14.0576 5.11046 13.1566 5.58777C12.2557 6.06508 11.4778 6.75893 10.8879 7.61148C10.2979 8.46403 9.91295 9.45056 9.76513 10.4888C9.61731 11.527 9.7109 12.5869 10.0381 13.58C10.3653 14.5731 10.9166 15.4706 11.646 16.1978C12.3755 16.9249 13.2619 17.4606 14.2315 17.7601C15.201 18.0597 16.2255 18.1144 17.2196 17.9197C18.2136 17.725 19.1484 17.2866 19.946 16.641C19.1871 16.0248 18.574 15.2369 18.1534 14.3374C17.7328 13.4379 17.5159 12.4506 17.5192 11.4508Z" fill="#EB001B" />
      <path d="M30.2101 11.5437C30.2049 12.4078 30.0356 13.2623 29.7117 14.0583C29.3879 14.8544 28.9159 15.5764 28.3229 16.1831C27.7298 16.7897 27.0273 17.2691 26.2555 17.5938C25.4836 17.9185 24.6577 18.0821 23.8248 18.0753C22.413 18.0642 21.0462 17.5589 19.9459 16.641C20.6991 16.0293 21.3093 15.2489 21.7308 14.3581C22.1522 13.4673 22.3741 12.4891 22.3799 11.4968C22.3856 10.5044 22.175 9.52358 21.7639 8.62757C21.3527 7.73156 20.7517 6.94358 20.0056 6.32255C21.1213 5.40851 22.5027 4.91382 23.9243 4.91923C25.6038 4.93559 27.2085 5.64205 28.3868 6.8838C29.565 8.12555 30.2207 9.80127 30.2101 11.5437Z" fill="#F79E1B" />
    </g>
    <defs>
      <clipPath id="clip0_6064_15964">
        <rect width="39.7838" height="22.9071" fill="white" transform="translate(0.0540771 0.0179443)" />
      </clipPath>
    </defs>
  </svg>

export default Icon;
