import React, { HTMLAttributes } from 'react';

interface DynamicTagProps extends HTMLAttributes<HTMLHeadingElement> {
  as: string;
}

export default function DynamicTag({ as, children, ...props }: DynamicTagProps): JSX.Element {
  const Tag = as as keyof JSX.IntrinsicElements;

  return React.createElement(Tag, { ...props }, children);
}
