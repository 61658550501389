import { useState } from 'react';
import clsx from 'clsx';
import { MdArrowForward } from 'react-icons/md';
import CoFeImage from '~/components/furniturechoice/co-fe-image';
import Link from '~/components/furniturechoice/link';
import isValidCoFeLink from '~/helpers/utils/furniturechoice/is-valid-co-fe-link';
import SubNavigation from './sub-navigation';
import { Menu as MenuType } from '../../types';

export interface MenuProps {
  menu: MenuType;
  onCloseSubNavigationClick(): void;
}

export default function Menu({ menu, onCloseSubNavigationClick }: MenuProps): JSX.Element {
  const [isSubNavigationExpanded, setIsSubNavigationExpanded] = useState<boolean>(false);

  const children = (
    <>
      <div
        className={clsx('flex h-11 w-11 items-center justify-center overflow-hidden rounded-full', {
          'bg-grey-2': menu.theme === 'default',
          'bg-red-2': menu.theme === 'saleRed',
        })}
      >
        <CoFeImage className="aspect-square object-contain" asset={menu.image?.media} sizes="44px" />
      </div>

      <p className="flex-1 text-18 font-bold">{menu.label}</p>
      <MdArrowForward className="text-24 text-on-white" />
    </>
  );

  if (menu.reference && isValidCoFeLink(menu.reference)) {
    return (
      <li className="h-[52px] w-full">
        <Link
          className={clsx('flex h-full w-full items-center gap-x-4 px-6 py-1', {
            'text-red-2': menu.theme === 'saleRed',
          })}
          data-name="mobile-nav-menu"
          data-testid="cta-link"
          reference={menu.reference}
        >
          {children}
        </Link>
      </li>
    );
  }

  return (
    <li className="h-[52px] w-full">
      <button
        className={clsx('flex h-full w-full items-center gap-x-4 px-6 py-1 text-left', {
          'text-red-2': menu.theme === 'saleRed',
        })}
        data-name="mobile-nav-menu"
        data-testid="cta-button"
        type="button"
        onClick={() => setIsSubNavigationExpanded(true)}
      >
        {children}
      </button>

      {(menu.primarySubNavigation || menu.secondarySubNavigations) && (
        <SubNavigation
          menu={menu}
          isExpanded={isSubNavigationExpanded}
          onCloseClick={onCloseSubNavigationClick}
          onBackClick={() => setIsSubNavigationExpanded(false)}
        />
      )}
    </li>
  );
}
