import * as React from 'react';
import { SWRConfig } from 'swr';
import { MinicartProvider } from '~/helpers/contexts/furniturechoice/minicart';
import { ProgressIndicatorProvider } from '~/helpers/contexts/furniturechoice/progress-indicator';

export const FrontasticProvider = ({ children }: React.PropsWithChildren) => {
  return (
    <SWRConfig>
      <ProgressIndicatorProvider>
        <MinicartProvider>{children}</MinicartProvider>
      </ProgressIndicatorProvider>
    </SWRConfig>
  );
};
