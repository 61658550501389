import Link from '~/components/furniturechoice/link';
import { Footnote as FootnoteType } from '../../types';

interface FootnoteProps {
  footnote: FootnoteType;
}

export default function Footnote({ footnote }: FootnoteProps): JSX.Element {
  return (
    <div className="flex flex-col gap-y-3">
      <p className="text-18 font-bold">{footnote.label}</p>

      <ul className="flex flex-col gap-y-2">
        {footnote.links.map((link) => (
          <li className="font-normal" key={link.label}>
            <Link reference={link.reference}>{link.label}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
