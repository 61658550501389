import Link from '~/components/furniturechoice/link';
import { Link as LinkType } from '../../../../types';

export interface SecondaryPanel {
  links: LinkType[];
}

export default function SecondaryPanel({ links }: SecondaryPanel): JSX.Element {
  return (
    <ul className="flex flex-col gap-y-3">
      {links.map((link) => (
        <li className="font-normal" key={link.label}>
          <Link reference={link.reference}>{link.label}</Link>
        </li>
      ))}
    </ul>
  );
}
