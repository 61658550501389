'use client';

import { ReactNode, useMemo, useState } from 'react';
import MinicartContext from './context';

export default function MinicartProvider({ children }: { children: ReactNode }) {
  const [isMinicartVisible, setIsMinicartVisible] = useState<boolean>(false);

  const minicartContextValue = useMemo(
    () => ({
      isMinicartVisible,
      openMinicart: () => setIsMinicartVisible(true),
      closeMinicart: () => setIsMinicartVisible(false),
    }),
    [isMinicartVisible],
  );

  return <MinicartContext.Provider value={minicartContextValue}>{children}</MinicartContext.Provider>;
}
