import { HTMLAttributes } from 'react';
import { type Cart } from 'shared/types/cart';
import { CurrencyHelpers } from '~/helpers/currencyHelpers';

interface CartTotalProps extends HTMLAttributes<HTMLDivElement> {
  cart: Cart | undefined;
  locale: string;
}

/**
 * Standardised way to display cart total
 * @param cart if cart is undefined, a blank div is returned
 * @param locale
 * @returns div containing the sum in currency
 */
export default function CartTotal({ cart, locale, ...props }: CartTotalProps) {
  return cart ? (
    <div {...props}>
      {CurrencyHelpers.formatForCurrency(
        cart.sum?.centAmount ?? 0,
        locale,
        cart.sum?.currencyCode,
        cart.sum?.fractionDigits,
      )}
    </div>
  ) : (
    <div {...props}></div>
  );
}
