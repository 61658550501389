import React from 'react';

type Props = {
  className?: string;
}

const Icon: React.FC<Props> = ({ className }: Props) =>
  <svg className={className} width="40" height="23" viewBox="0 0 40 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6064_15942)">
      <path d="M4.87096 22.213C3.66397 22.2263 2.5 21.7484 1.62859 20.8819C1.34186 20.5957 1.1132 20.2529 0.956063 19.8737C0.798921 19.4945 0.71647 19.0865 0.713562 18.6737L0.813021 4.02147C0.832913 2.08159 2.70275 0.533813 4.99032 0.533813L35.3453 0.729865C36.5551 0.722101 37.7195 1.20751 38.5877 2.08159C38.8744 2.36777 39.1031 2.71055 39.2602 3.08977C39.4174 3.46899 39.4998 3.87698 39.5027 4.28975L39.4033 18.942C39.4033 20.8613 37.5135 22.4297 35.226 22.4297L4.87096 22.213Z" fill="white" />
      <path d="M4.9903 0.0179443V1.04979L35.3155 1.24585C35.8472 1.2352 36.3758 1.33363 36.8707 1.5355C37.3657 1.73736 37.8174 2.03868 38.1998 2.42215C38.4473 2.65762 38.6464 2.94267 38.7848 3.26011C38.9233 3.57756 38.9983 3.92081 39.0054 4.26917L38.9059 18.9214C38.9059 20.5621 37.245 21.8932 35.1961 21.8932L4.87095 21.6868C4.3392 21.6974 3.81066 21.599 3.31568 21.3971C2.8207 21.1953 2.36904 20.894 1.98662 20.5105C1.74568 20.2736 1.5529 19.989 1.41967 19.6735C1.28643 19.3581 1.21542 19.0182 1.21084 18.6738L1.3103 4.02152C1.33019 2.38088 2.97127 1.04979 4.9903 1.04979V0.0179443ZM4.9903 0.0179443C2.43419 0.0179443 0.335599 1.81336 0.315707 4.02152L0.216248 18.6738C0.216248 20.8923 2.29495 22.7186 4.861 22.7186L35.1961 22.9147C37.7522 22.9147 39.8508 21.1193 39.8707 18.9111L40 4.26917C40 2.08164 37.9213 0.224314 35.3552 0.213996L5.02014 0.0179443H4.9903Z" fill="#CCCCCC" />
      <path fillRule="evenodd" clipRule="evenodd" d="M8.72001 13.6899H7.17839C7.14673 13.6899 7.11638 13.6769 7.09399 13.6537C7.07161 13.6305 7.05904 13.599 7.05904 13.5661V9.37681C7.06925 9.35341 7.08623 9.33389 7.10761 9.32095C7.129 9.30801 7.15373 9.30229 7.17839 9.30458H8.74985C9.03319 9.30455 9.31371 9.36294 9.57512 9.47636C9.83652 9.58978 10.0736 9.75596 10.2726 9.96526C10.4715 10.1746 10.6284 10.4228 10.7341 10.6955C10.8398 10.9683 10.8922 11.2601 10.8882 11.554C10.8625 12.1316 10.6225 12.6765 10.2184 13.0746C9.81423 13.4727 9.27731 13.6932 8.72001 13.6899ZM8.72001 8.21082H6.18379C6.14159 8.21082 6.10111 8.22821 6.07127 8.25917C6.04142 8.29013 6.02466 8.33213 6.02466 8.37591V14.567C6.02466 14.6108 6.04142 14.6528 6.07127 14.6837C6.10111 14.7147 6.14159 14.7321 6.18379 14.7321H8.71006C9.1228 14.7321 9.53149 14.6478 9.9128 14.4839C10.2941 14.32 10.6406 14.0799 10.9324 13.7771C11.2243 13.4743 11.4558 13.1149 11.6137 12.7193C11.7717 12.3237 11.853 11.8997 11.853 11.4715C11.853 11.0433 11.7717 10.6193 11.6137 10.2237C11.4558 9.82807 11.2243 9.46861 10.9324 9.16584C10.6406 8.86306 10.2941 8.62288 9.9128 8.45902C9.53149 8.29515 9.1228 8.21082 8.71006 8.21082H8.72001Z" fill="#122E34" />
      <path fillRule="evenodd" clipRule="evenodd" d="M31.5161 8.13855C30.8807 8.13855 30.2596 8.33402 29.7313 8.70024C29.203 9.06646 28.7913 9.58699 28.5481 10.196C28.305 10.805 28.2414 11.4751 28.3653 12.1216C28.4893 12.7682 28.7952 13.362 29.2445 13.8281C29.6938 14.2942 30.2662 14.6117 30.8894 14.7403C31.5126 14.8689 32.1585 14.8029 32.7455 14.5506C33.3325 14.2983 33.8343 13.8712 34.1873 13.3231C34.5403 12.775 34.7287 12.1306 34.7287 11.4714C34.7287 11.0337 34.6456 10.6004 34.4841 10.196C34.3227 9.79163 34.086 9.42421 33.7877 9.11473C33.4894 8.80524 33.1353 8.55974 32.7455 8.39225C32.3557 8.22476 31.938 8.13855 31.5161 8.13855ZM31.5161 9.1704C31.9548 9.1704 32.3836 9.30535 32.7484 9.55819C33.1131 9.81103 33.3974 10.1704 33.5652 10.5909C33.7331 11.0113 33.777 11.474 33.6915 11.9203C33.6059 12.3667 33.3946 12.7767 33.0845 13.0985C32.7743 13.4203 32.3791 13.6395 31.9488 13.7282C31.5186 13.817 31.0726 13.7715 30.6674 13.5973C30.2621 13.4231 29.9157 13.1282 29.672 12.7498C29.4283 12.3714 29.2982 11.9265 29.2982 11.4714C29.2982 10.8612 29.5319 10.2759 29.9478 9.84435C30.3638 9.41283 30.9279 9.1704 31.5161 9.1704Z" fill="#122E34" />
      <path fillRule="evenodd" clipRule="evenodd" d="M22.4653 14.7321H21.7691C21.7269 14.7321 21.6864 14.7147 21.6565 14.6837C21.6267 14.6528 21.6099 14.6108 21.6099 14.567V8.37591C21.6099 8.33213 21.6267 8.29013 21.6565 8.25917C21.6864 8.22821 21.7269 8.21082 21.7691 8.21082H22.4653C22.5057 8.21344 22.5437 8.23199 22.5714 8.26267C22.5992 8.29335 22.6145 8.33386 22.6145 8.37591V14.567C22.6145 14.6091 22.5992 14.6496 22.5714 14.6802C22.5437 14.7109 22.5057 14.7295 22.4653 14.7321Z" fill="#122E34" />
      <path fillRule="evenodd" clipRule="evenodd" d="M19.5113 9.08789V8.37591C19.5113 8.33213 19.4946 8.29013 19.4647 8.25917C19.4349 8.22821 19.3944 8.21082 19.3522 8.21082H13.7825C13.742 8.21344 13.7041 8.23199 13.6763 8.26267C13.6486 8.29335 13.6332 8.33386 13.6333 8.37591V9.08789C13.6332 9.12994 13.6486 9.17045 13.6763 9.20113C13.7041 9.23181 13.742 9.25036 13.7825 9.25298H19.3522C19.3944 9.25298 19.4349 9.23559 19.4647 9.20463C19.4946 9.17367 19.5113 9.13167 19.5113 9.08789Z" fill="#E3636F" />
      <path fillRule="evenodd" clipRule="evenodd" d="M13.6333 13.855V14.567C13.6332 14.6091 13.6486 14.6496 13.6763 14.6803C13.7041 14.7109 13.742 14.7295 13.7825 14.7321H19.3522C19.3944 14.7321 19.4349 14.7147 19.4647 14.6838C19.4946 14.6528 19.5113 14.6108 19.5113 14.567V13.855C19.5113 13.8113 19.4946 13.7693 19.4647 13.7383C19.4349 13.7073 19.3944 13.6899 19.3522 13.6899H13.7825C13.742 13.6926 13.7041 13.7111 13.6763 13.7418C13.6486 13.7725 13.6332 13.813 13.6333 13.855Z" fill="#EB9D3F" />
      <path fillRule="evenodd" clipRule="evenodd" d="M19.5113 11.8326V11.1103C19.5113 11.0665 19.4946 11.0245 19.4647 10.9935C19.4349 10.9626 19.3944 10.9452 19.3522 10.9452H13.7825C13.742 10.9478 13.7041 10.9664 13.6763 10.997C13.6486 11.0277 13.6332 11.0682 13.6333 11.1103V11.8326C13.6332 11.8746 13.6486 11.9151 13.6763 11.9458C13.7041 11.9765 13.742 11.995 13.7825 11.9977H19.3522C19.3944 11.9977 19.4349 11.9803 19.4647 11.9493C19.4946 11.9184 19.5113 11.8764 19.5113 11.8326Z" fill="#24B095" />
      <path fillRule="evenodd" clipRule="evenodd" d="M24.5838 11.3682L27.5675 8.27269C27.5805 8.26733 27.5915 8.25808 27.5993 8.24612C27.6071 8.23417 27.6113 8.22006 27.6113 8.20562C27.6113 8.19118 27.6071 8.17707 27.5993 8.16512C27.5915 8.15316 27.5805 8.14391 27.5675 8.13855H26.3342C26.2818 8.13933 26.2317 8.16158 26.195 8.20046L23.3306 11.1722C23.3004 11.2012 23.2764 11.2363 23.26 11.2753C23.2435 11.3144 23.235 11.3566 23.235 11.3992C23.235 11.4418 23.2435 11.484 23.26 11.523C23.2764 11.5621 23.3004 11.5972 23.3306 11.6262L26.195 14.5979C26.2317 14.6368 26.2818 14.6591 26.3342 14.6598H27.488C27.5009 14.6545 27.512 14.6452 27.5198 14.6333C27.5276 14.6213 27.5317 14.6072 27.5317 14.5928C27.5317 14.5783 27.5276 14.5642 27.5198 14.5523C27.512 14.5403 27.5009 14.5311 27.488 14.5257L24.5042 11.4302C24.4895 11.4131 24.4814 11.3911 24.4814 11.3682C24.4814 11.3454 24.4895 11.3234 24.5042 11.3063" fill="#122E34" />
    </g>
    <defs>
      <clipPath id="clip0_6064_15942">
        <rect width="39.7838" height="22.9071" fill="white" transform="translate(0.216248 0.0179443)" />
      </clipPath>
    </defs>
  </svg>

export default Icon;
