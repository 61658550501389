import clsx from 'clsx';
import Link from '~/components/furniturechoice/link';
import MobileMegamenuContainer from '~/components/furniturechoice/mobile-megamenu-container';
import MobileMegamenuNewsletterCta from '~/components/furniturechoice/mobile-megamenu-newsletter-cta';
import usePopupFocusEffect from '~/helpers/hooks/furniturechoice/use-popup-focus-effect';
import Footnote from './footnote';
import Menu from './menu';
import { Footnote as FootnoteType, Menu as MenuType } from '../types';

export interface MobileMegamenuProps {
  menus: MenuType[];
  footnote: FootnoteType;
  isExpanded: boolean;
  onCloseClick(): void;
}

export default function MobileMegamenu({
  menus,
  footnote,
  isExpanded,
  onCloseClick,
}: MobileMegamenuProps): JSX.Element {
  usePopupFocusEffect({ isPopupVisible: isExpanded, manageRootDocScroll: true });

  const regularMenus = menus.filter((menu) => !menu.isCompact);
  const compactMenus = menus.filter((menu) => menu.isCompact);

  return (
    <MobileMegamenuContainer
      className={clsx('full-screen-transition fixed inset-0 z-100', {
        'slide-left-initial': !isExpanded,
        'slide-left-enter': isExpanded,
      })}
      data-testid="mobile-megamenu"
      as="nav"
      inert={!isExpanded ? '' : undefined}
      onCloseClick={onCloseClick}
    >
      <div className="flex flex-1 flex-col gap-y-12 overflow-auto">
        <ul className="mt-6 flex flex-col gap-y-3">
          {regularMenus.map((menu) => (
            <Menu key={menu.label} menu={menu} onCloseSubNavigationClick={onCloseClick} />
          ))}
        </ul>

        <div className="flex flex-col gap-y-7 px-6">
          <ul className="flex flex-col gap-y-7">
            {compactMenus.map((menu) => (
              <Link key={menu.label} className="text-18 font-bold" reference={menu.reference}>
                {menu.label}
              </Link>
            ))}
          </ul>

          <Footnote footnote={footnote} />
        </div>

        <MobileMegamenuNewsletterCta className="mt-auto" onClick={onCloseClick} />
      </div>
    </MobileMegamenuContainer>
  );
}
