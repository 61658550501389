import clsx from 'clsx';
import { FNCLineItem } from 'shared/types/cart/LineItem';
import { CurrencyHelpers } from '~/helpers/currencyHelpers';
import { getLineItemDisplayPrice } from '~/helpers/furniturechoice/displayPriceHelper';

interface CartPricingProps {
  item: FNCLineItem;
  locale: string;
  component?: 'cart' | 'minicart';
}

export default function CartPricing({ item, locale, component = 'cart' }: CartPricingProps): JSX.Element {
  const totalFullPrice = (item.price?.centAmount ?? 0) * (item.count ?? 0);
  return (
    <>
      <span
        data-testid="main-price"
        className={clsx(
          'font-semibold',
          { 'text-24': component === 'cart' },
          { 'text-18': component === 'minicart' },
          { 'text-red-1': item.discountedPrice || item.variant.attributes?.['descriptors_is-clearance'] },
        )}
      >
        {CurrencyHelpers.formatForCurrency(getLineItemDisplayPrice(item) ?? 0, locale)}
      </span>
      {item.discountedPrice && (
        <p className="text-14 text-grey-5 line-through" data-testid="compare-at-price">
          {CurrencyHelpers.formatForCurrency(
            totalFullPrice,
            locale,
            item.price?.currencyCode,
            item.price?.fractionDigits,
          )}
        </p>
      )}
    </>
  );
}
