import { HTMLAttributes } from 'react';
import { FNCLineItem } from 'shared/types/cart/LineItem';
import ReviewStars from '~/components/furniturechoice/review/ReviewStars';
import { ReviewSummaryHelper } from '~/helpers/furniturechoice/review/review-summary';
import useProduct from '~/helpers/hooks/furniturechoice/use-product';

interface ReviewWidgetCart extends HTMLAttributes<HTMLDivElement> {
  product: FNCLineItem;
}

export default function ReviewWidgetCart({ product, ...props }: ReviewWidgetCart) {
  const baseIdReview = product.variant.attributes?.baseid_review;
  const { data } = useProduct({ sku: baseIdReview });
  if (typeof baseIdReview === 'undefined') {
    return <></>;
  }

  const stats = data?.reviewRatingStatistics;

  if (!stats || !stats.count) return <></>;

  return (
    <div className="flex" {...props}>
      <div className="flex items-center gap-2 text-left align-middle font-sans">
        <span className="inline-block text-12 leading-6 text-grey-5">
          {ReviewSummaryHelper.getAverageRating(stats)}
        </span>
        <ReviewStars stars={stats.averageRating} className="fill-grey-5 text-14" />
        <div className="text-12 text-salmon-1">({stats.count})</div>
      </div>
    </div>
  );
}
