import { HTMLAttributes } from 'react';
import clsx from 'clsx';
import { MdClose, MdPersonOutline } from 'react-icons/md';
import { useFormat } from '~/helpers/hooks/useFormat';
import DynamicTag from '../dynamic-tag';
import Link from '../link';

export interface MobileMegamenuContainerProps extends HTMLAttributes<HTMLDivElement> {
  as?: string;
  onCloseClick(): void;
}

export default function MobileMegamenuContainer({
  children,
  className,
  as = 'div',
  onCloseClick,
  ...props
}: MobileMegamenuContainerProps): JSX.Element {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  return (
    <DynamicTag className={clsx('surface-white flex flex-col shadow lg:hidden', className)} as={as} {...props}>
      <div className="flex items-center justify-between py-4 pl-5 pr-3">
        <Link className="flex items-center gap-x-2" href="/sign-in">
          <MdPersonOutline className="text-24" />

          <span className="text-22 font-bold">
            {formatMessage({ id: 'components.mobile-nav-drawer-container.sign_in' })}
          </span>
        </Link>

        <button data-testid="close-megamenu-btn" className="p-1" onClick={onCloseClick}>
          <MdClose className="text-38" />

          <span className="sr-only">
            {formatMessage({ id: 'components.mobile-nav-drawer-container.close_megamenu' })}
          </span>
        </button>
      </div>

      {children}
    </DynamicTag>
  );
}
