import { ComponentProps } from 'react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import Link from '~/components/furniturechoice/link';

interface FNCLinkButtonProps extends ComponentProps<typeof Link> {
  disabled?: boolean;
  icon?: JSX.Element;
  iconPosition?: 'start' | 'end';
}

export default function FNCLinkButton({
  className,
  children,
  reference,
  disabled,
  icon,
  iconPosition = 'start',
  ...props
}: FNCLinkButtonProps): JSX.Element {
  return (
    <Link
      className={twMerge(
        clsx(
          'flex min-h-[40px] min-w-[120px] items-center justify-center gap-x-2 rounded-md px-6 py-2 text-center font-semibold',
          className,
          {
            'opacity-[0.38] marker:pointer-events-none': disabled,
            'hover:brightness-90': !disabled,
          },
        ),
      )}
      reference={reference}
      {...props}
    >
      {icon && iconPosition === 'start' && (
        <span className="[&>svg]:text-20" data-testid="start-icon">
          {icon}
        </span>
      )}

      {children && <span>{children}</span>}

      {icon && iconPosition === 'end' && (
        <span className={clsx({ '[&>svg]:text-20': children, '[&>svg]:text-30': !children })} data-testid="end-icon">
          {icon}
        </span>
      )}
    </Link>
  );
}
