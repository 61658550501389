import { Fragment, HTMLAttributes, useId, useState } from 'react';
import FooterAccordionHeader from './footer-accordion-header';
import FooterAccordionPanel from './footer-accordion-panel';

export interface FooterAccordionContent {
  headingText: string;
  panel: JSX.Element | string;
  isExpanded: boolean;
}

interface FooterAccordionProps extends HTMLAttributes<HTMLDivElement> {
  contents: FooterAccordionContent[];
}

/**
 * Separate accordion used specifically for the site footer only
 */
export default function FooterAccordion({ contents, ...props }: FooterAccordionProps): JSX.Element {
  const [accordionStatuses, setAccordionStatuses] = useState<boolean[]>(contents.map(() => false));
  const headingId = useId();
  const panelId = useId();

  function handleHeadingClick(indexToUpdate: number): void {
    const newAccordionStatuses = accordionStatuses.map((status, index) => {
      if (index === indexToUpdate) {
        return !status;
      }

      return status;
    });

    setAccordionStatuses(newAccordionStatuses);
  }

  const contentEls = contents.map((content, index) => {
    const headingIndexId = `${headingId}${index}`;
    const panelIndexId = `${panelId}${index}`;

    if (content.panel) {
      return (
        <Fragment key={content.headingText}>
          <div className="pt-2 md:block md:w-1/3 md:border-0 md:pb-8 md:pt-4 lg:mt-0 lg:w-auto">
            <FooterAccordionHeader
              headingId={headingIndexId}
              headingText={content.headingText}
              panelId={panelIndexId}
              isExpanded={accordionStatuses[index]}
              onClick={() => handleHeadingClick(index)}
            />
            <FooterAccordionPanel
              panelId={panelIndexId}
              panelContent={content.panel}
              headingId={headingIndexId}
              isExpanded={accordionStatuses[index]}
            />
          </div>
        </Fragment>
      );
    } else {
      return null;
    }
  });

  return <div {...props}>{contentEls}</div>;
}
