import clsx from 'clsx';
import { MdArrowBack } from 'react-icons/md';
import MobileMegamenuContainer from '~/components/furniturechoice/mobile-megamenu-container';
import MobileMegamenuNewsletterCta from '~/components/furniturechoice/mobile-megamenu-newsletter-cta';
import usePopupFocusEffect from '~/helpers/hooks/furniturechoice/use-popup-focus-effect';
import { useFormat } from '~/helpers/hooks/useFormat';
import AccordionItem from './accordion-item';
import PrimaryPanel from './primary-panel';
import SecondaryPanel from './secondary-panel';
import { Menu } from '../../../types';

export interface SubNavigationProps {
  menu: Menu;
  isExpanded: boolean;
  onCloseClick(): void;
  onBackClick(): void;
}

export default function SubNavigation({
  menu,
  isExpanded,
  onCloseClick,
  onBackClick,
}: SubNavigationProps): JSX.Element {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });
  const numOfAccordion = [menu.primarySubNavigation[0], ...menu.secondarySubNavigations].filter(
    (i) => i !== undefined,
  ).length;

  usePopupFocusEffect({ isPopupVisible: isExpanded, manageRootDocScroll: false });

  return (
    <MobileMegamenuContainer
      className={clsx('full-screen-transition fixed inset-0 z-100', {
        'slide-left-initial': !isExpanded,
        'slide-left-enter': isExpanded,
      })}
      data-testid="sub-navigation"
      inert={!isExpanded ? '' : undefined}
      onCloseClick={onCloseClick}
    >
      <div className="mt-4 px-4">
        <div className="surface-grey-1 flex items-center gap-x-5 rounded-md px-4 py-3">
          <button data-testid="close-megamenu-btn" className="p-1" onClick={onBackClick}>
            <MdArrowBack className="text-24" />
            <span className="sr-only">{formatMessage({ id: 'tastics.core.site-header.close_megamenu' })}</span>
          </button>

          <p className="text-22 font-bold">{menu.label}</p>
        </div>
      </div>

      <div className="mt-6 flex flex-1 flex-col gap-y-5 overflow-auto">
        {menu.primarySubNavigation[0] && (
          <AccordionItem
            label={menu.primarySubNavigation[0].label}
            image={menu.image}
            defaultIsExpanded
            isExpandable={numOfAccordion > 1}
          >
            <PrimaryPanel groups={menu.primarySubNavigation[0].groups} />
          </AccordionItem>
        )}

        {menu.secondarySubNavigations.map((subNavigation) => (
          <AccordionItem
            key={subNavigation.label}
            label={subNavigation.label}
            image={subNavigation.image}
            defaultIsExpanded={numOfAccordion <= 1}
          >
            <SecondaryPanel links={subNavigation.links} />
          </AccordionItem>
        ))}

        <MobileMegamenuNewsletterCta className="mt-auto" onClick={onCloseClick} />
      </div>
    </MobileMegamenuContainer>
  );
}
