import clsx from 'clsx';
import DesktopMegamenuFooter from '~/components/furniturechoice/desktop-megamenu-footer';
import Primary from './primary';
import Secondary from './secondary';
import { Footnote, Menu } from '../../../types';

export interface SubNavigationProps {
  menu: Menu;
  footnote: Footnote;
  isExpanded: boolean;
  onNewsletterCtaClick(): void;
}

export default function SubNavigation({
  menu,
  footnote,
  isExpanded,
  onNewsletterCtaClick,
}: SubNavigationProps): JSX.Element {
  return (
    <div
      className={clsx(
        'surface-white absolute inset-x-0 bottom-0 -z-25 flex max-h-[calc(92vh-150px)] flex-col gap-y-14 overflow-auto pt-10',
        {
          '-translate-y-full transition-transform duration-exit ease-exit': !isExpanded,
          'translate-y-[calc(100%-1px)] duration-enter ease-enter': isExpanded,
        },
      )}
      data-testid="sub-navigation"
      inert={!isExpanded ? '' : undefined}
    >
      {menu.primarySubNavigation[0] && <Primary subNavigation={menu.primarySubNavigation[0]} />}
      {menu.secondarySubNavigations.length > 0 && <Secondary subNavigations={menu.secondarySubNavigations} />}
      <DesktopMegamenuFooter footnote={footnote} onNewsletterCtaClick={onNewsletterCtaClick} />
    </div>
  );
}
