import clsx from 'clsx';
import { MdOutlineStar, MdOutlineStarBorder, MdOutlineStarHalf } from 'react-icons/md';

interface ReviewStarsProps {
  stars: number;
  className: string;
  justify?: 'left' | 'center' | 'right';
}

export default function ReviewStars({ stars, className, justify }: ReviewStarsProps) {
  const Star = ({ value, className }: { value: number; className: string }) => {
    if (value <= 0.2) {
      return <MdOutlineStarBorder className={className} />;
    } else if (value >= 0.8) {
      return <MdOutlineStar className={className} />;
    } else {
      return <MdOutlineStarHalf className={className} />;
    }
  };

  const starClass = clsx('inline-block w-auto', className);

  return (
    <div
      className={clsx(
        'flex items-center',
        { 'justify-left': justify == 'left' },
        { 'justify-center': justify == 'center' },
        { 'justify-righr': justify == 'right' },
        { 'justify-center': !justify },
      )}
    >
      <Star className={starClass} value={stars} />
      <Star className={starClass} value={stars - 1} />
      <Star className={starClass} value={stars - 2} />
      <Star className={starClass} value={stars - 3} />
      <Star className={starClass} value={stars - 4} />
    </div>
  );
}
