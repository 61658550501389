import Link from '~/components/furniturechoice/link';
import { Group as GroupType } from '../../../../../types';

export interface GroupProps {
  group: GroupType;
  isLabelVisible: boolean;
}

export default function Group({ group, isLabelVisible }: GroupProps): JSX.Element {
  return (
    <div className="flex flex-col gap-y-3">
      {isLabelVisible && <p className="font-bold">{group.label}</p>}

      <ul className="flex flex-col gap-y-3">
        {group.links.map((link) => (
          <li className="font-normal" key={link.label}>
            <Link reference={link.reference}>{link.label}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
