import React from 'react';

type Props = {
  className?: string;
}

const Icon: React.FC<Props> = ({ className }: Props) =>
  <svg className={className} width="40" height="23" viewBox="0 0 40 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6064_15982)">
      <path d="M4.65472 22.213C3.44772 22.2263 2.28375 21.7484 1.41234 20.8819C1.12562 20.5957 0.896957 20.2529 0.739815 19.8737C0.582673 19.4945 0.500223 19.0865 0.497314 18.6737L0.596774 4.02147C0.616666 2.08159 2.4865 0.533813 4.77407 0.533813L35.1291 0.729865C36.3389 0.722101 37.5032 1.20751 38.3714 2.08159C38.6582 2.36777 38.8868 2.71055 39.044 3.08977C39.2011 3.46899 39.2836 3.87698 39.2865 4.28975L39.187 18.942C39.187 20.8613 37.2973 22.4297 35.0097 22.4297L4.65472 22.213Z" fill="white" />
      <path d="M4.77405 0.0179443V1.04979L35.0992 1.24585C35.631 1.2352 36.1595 1.33363 36.6545 1.5355C37.1495 1.73736 37.6011 2.03868 37.9835 2.42215C38.2311 2.65762 38.4301 2.94267 38.5686 3.26011C38.707 3.57756 38.7821 3.92081 38.7892 4.26917L38.6897 18.9214C38.6897 20.5621 37.0287 21.8932 34.9799 21.8932L4.6547 21.6868C4.12295 21.6974 3.59441 21.599 3.09943 21.3971C2.60445 21.1953 2.15279 20.894 1.77038 20.5105C1.52943 20.2736 1.33666 19.989 1.20342 19.6735C1.07018 19.3581 0.999175 19.0182 0.994594 18.6738L1.09405 4.02152C1.11395 2.38088 2.75503 1.04979 4.77405 1.04979V0.0179443ZM4.77405 0.0179443C2.21794 0.0179443 0.119351 1.81336 0.0994594 4.02152L0 18.6738C0 20.8923 2.0787 22.7186 4.64475 22.7186L34.9799 22.9147C37.536 22.9147 39.6346 21.1193 39.6545 18.9111L39.7838 4.26917C39.7838 2.08164 37.7051 0.224314 35.139 0.213996L4.80389 0.0179443H4.77405Z" fill="#CCCCCC" />
      <path d="M15.8737 15.6195L17.296 7.29248L19.4841 7.31312L18.0618 15.6298L15.8737 15.6195Z" fill="#005098" />
      <path d="M26.0484 7.56073C25.4105 7.322 24.7374 7.19981 24.0592 7.19958C21.9308 7.24086 20.3991 8.27271 20.3693 9.92367C20.3693 11.1206 21.4534 11.8016 22.2789 12.2041C23.1044 12.6065 23.4128 12.8748 23.4028 13.2359C23.4028 13.7931 22.7165 14.0407 22.09 14.0407C21.3791 14.0563 20.6744 13.9009 20.0311 13.5867L19.7527 13.432L19.4344 15.2893C20.2133 15.594 21.0387 15.7512 21.8711 15.7536C24.1687 15.7536 25.6705 14.6599 25.7003 12.9367C25.7003 11.9874 25.1334 11.2651 23.8802 10.6563C23.1144 10.2642 22.6469 10.0062 22.6569 9.62443C22.6668 9.24265 23.0547 8.91246 23.9101 8.92277C24.4771 8.92725 25.0366 9.05742 25.5511 9.30456L25.7401 9.39743L26.0484 7.59169V7.56073Z" fill="#005098" />
      <path d="M31.6679 7.406H29.9771C29.7437 7.37657 29.5074 7.42592 29.3032 7.54672C29.099 7.66753 28.9377 7.85336 28.8432 8.0767L25.5412 15.6917H27.8487L28.2962 14.4638H31.091C31.1607 14.7528 31.3496 15.7124 31.3496 15.7124H33.3885L31.6679 7.406ZM28.9328 12.7613C29.1118 12.2763 29.8179 10.4293 29.8179 10.4293C29.8179 10.4293 30.0069 9.95466 30.1163 9.64511L30.2556 10.3571C30.2556 10.3571 30.6634 12.3486 30.7529 12.7716L28.9328 12.7613Z" fill="#005098" />
      <path d="M14.0934 7.24084L11.9351 12.947L11.7163 11.7913C11.165 10.266 10.0826 9.01058 8.6828 8.27269L10.5924 15.5782H12.9297L16.4307 7.32339L14.0934 7.24084Z" fill="#005098" />
      <path d="M9.94591 7.24084H6.42505V7.41626C7.58546 7.64628 8.67411 8.167 9.59629 8.9331C10.5185 9.6992 11.2462 10.6875 11.7163 11.8119L10.9405 7.95282C10.8212 7.42658 10.4333 7.2718 9.94591 7.25116V7.24084Z" fill="#F6A500" />
    </g>
    <defs>
      <clipPath id="clip0_6064_15982">
        <rect width="39.7838" height="22.9071" fill="white" transform="translate(0 0.0179443)" />
      </clipPath>
    </defs>
  </svg>

export default Icon;
