interface ImageSizeConfig {
  colSpanPercent?: number;
  colSpanPercentSm?: number;
  colSpanPercentMd?: number;
  colSpanPercentLg?: number;
  colSpanPercentXl?: number;
  colSpanPercent2Xl?: number;
}

export default function buildImageSizes({
  colSpanPercent = 1,
  colSpanPercentSm,
  colSpanPercentMd,
  colSpanPercentLg,
  colSpanPercentXl,
  colSpanPercent2Xl,
}: ImageSizeConfig): string {
  const finalColSpanPercentSm = colSpanPercentSm || colSpanPercent;
  const finalColSpanPercentMd = colSpanPercentMd || finalColSpanPercentSm;
  const finalColSpanPercentLg = colSpanPercentLg || finalColSpanPercentMd;
  const finalColSpanPercentXl = colSpanPercentXl || finalColSpanPercentLg;
  const finalColSpanPercent2Xl = colSpanPercent2Xl || finalColSpanPercentXl;

  return `(min-width: 1536px) calc(1536px * ${finalColSpanPercent2Xl}), 
    (min-width: 1280px) calc(1280px * ${finalColSpanPercentXl}), 
    (min-width: 1024px) calc(1024px * ${finalColSpanPercentLg}), 
    (min-width: 768px) calc(768px * ${finalColSpanPercentMd}), 
    (min-width: 640px) calc(640px * ${finalColSpanPercentSm}), 
    calc(100vw * ${colSpanPercent})
  `
    .replace(/\s+/g, ' ')
    .trim();
}
