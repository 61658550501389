import { ComponentProps } from 'react';
import { FNCAsset } from 'shared/types/furniturechoice/asset';
import useProductMainImage from '~/helpers/hooks/furniturechoice/use-product-main-image';
import ContentfulImage from '../contentful-image';

interface ProductMainImageProps extends Omit<ComponentProps<typeof ContentfulImage>, 'src'> {
  sku: string;
  defaultImage?: FNCAsset;
}

export default function ProductMainImage({ sku, defaultImage, ...props }: ProductMainImageProps): JSX.Element {
  const { data } = useProductMainImage({ sku, defaultImage });

  return <ContentfulImage src={data?.uri} alt={data?.alt} {...props} />;
}
