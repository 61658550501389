import { HTMLAttributes } from 'react';
import clsx from 'clsx';
import { MdArrowForward } from 'react-icons/md';
import { Footnote } from '~/frontastic/tastics/furniturechoice/core/site-header/types';
import { useFormat } from '~/helpers/hooks/useFormat';
import Link from '../link';

interface DesktopMegamenuFooterProps extends HTMLAttributes<HTMLButtonElement> {
  footnote: Footnote;
  onNewsletterCtaClick(): void;
}

export default function DesktopMegamenuFooter({
  className,
  footnote,
  onNewsletterCtaClick,
  ...props
}: DesktopMegamenuFooterProps): JSX.Element {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  function handleClick() {
    onNewsletterCtaClick();

    setTimeout(() => {
      // Use native browser instead of router.push because CSS smooth-scroll doesn't integrate well
      location.hash = '';
      location.hash = 'newsletter';
    });
  }

  return (
    <footer
      className={clsx('surface-grey-2 flex min-h-[68px] items-center border-t border-solid border-grey-3', className)}
    >
      <div className="container">
        <div className="flex items-center justify-between px-4">
          <div className="flex items-center gap-x-9">
            <p className="text-18 font-bold">{footnote.label}</p>

            <ul className="flex items-center gap-x-2">
              {footnote.links.map((link) => (
                <li
                  key={link.label}
                  className="border-solid border-on-white [&:not(:first-child):not(:last-child)]:border-x [&:not(:first-child):not(:last-child)]:px-2"
                >
                  <Link className="color-change-transition font-normal hover:text-salmon-5" reference={link.reference}>
                    {link.label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <button className="flex items-center gap-x-6" type="button" onClick={() => handleClick()} {...props}>
            <p className="flex-1 text-18 font-bold">
              {formatMessage({ id: 'components.desktop-megamenu-footer.subscribe_to_newsletter' })}
            </p>

            <MdArrowForward className="text-24" />
          </button>{' '}
        </div>
      </div>
    </footer>
  );
}
