import { RefObject, useEffect, useState } from 'react';

interface Props {
  isPopupVisible: boolean;
  focusRef?: RefObject<HTMLElement> | string;
  manageRootDocScroll?: boolean;
}

/*
 * Used to manage the focus states for pop-up contents such as navigation drawers or dialogs.
 * This will return back to the previous focused element once the pop-up is closed.
 * There's also an option to prevent outside scrolling to avoid scroll hijacking when both
 * <body> and the pop-up content has scrolls.
 */
export default function usePopupFocusEffect({ isPopupVisible, focusRef, manageRootDocScroll }: Props): void {
  const [prevFocus, setPrevFocus] = useState<Element | null>(null);

  useEffect(() => {
    if (isPopupVisible) {
      if (manageRootDocScroll) {
        document.documentElement.style.setProperty('overflow', 'hidden');
      }

      setPrevFocus(document.activeElement);

      if (focusRef) {
        if (typeof focusRef === 'string') {
          document.querySelector<HTMLElement>(focusRef)?.focus();
        } else {
          focusRef.current?.focus();
        }
      }
    } else {
      if (manageRootDocScroll) {
        document.documentElement.style.removeProperty('overflow');
      }

      if (focusRef) {
        if (typeof focusRef === 'string') {
          document.querySelector<HTMLElement>(focusRef)?.blur();
        } else {
          focusRef.current?.blur();
        }
      }

      if (prevFocus) {
        (prevFocus as HTMLElement).focus();
      }
    }

    return () => {
      if (manageRootDocScroll) {
        document.documentElement.style.removeProperty('overflow');
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPopupVisible]);
}
