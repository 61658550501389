import { Reference } from '~/types/reference';

export default function isValidCoFeLink(reference: Reference): boolean {
  try {
    if (reference.type === 'link' && reference.link) {
      return true;
    }

    if (reference.type === 'page-folder' && reference.pageFolder._url) {
      return true;
    }

    return false;
  } catch {
    return false;
  }
}
