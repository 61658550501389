import { useContext } from 'react';
import { FNCAsset } from 'shared/types/furniturechoice/asset';
import { SWRResponse } from 'swr';
import useSWRImmutable from 'swr/immutable';
import { sdk } from 'sdk';
import { ProgressIndicatorContext } from '~/helpers/contexts/furniturechoice/progress-indicator';

interface UseProductMainImageArgs {
  sku: string;
  defaultImage?: FNCAsset;
}

export default function useProductMainImage({ sku, defaultImage }: UseProductMainImageArgs): SWRResponse<FNCAsset> {
  const { executeWithProgress } = useContext(ProgressIndicatorContext);

  return useSWRImmutable<FNCAsset>(`/action/furniturechoice-product/getMainImage/${sku}`, async () => {
    return executeWithProgress<FNCAsset>(async () => {
      if (defaultImage) {
        return defaultImage;
      }

      const response = await sdk.callAction<FNCAsset>({
        actionName: 'furniturechoice-product/getMainImage',
        payload: { sku },
      });

      if (response.isError) {
        throw response.error;
      }

      return response.data;
    });
  });
}
