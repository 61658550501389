import clsx from 'clsx';
import { MdKeyboardArrowDown } from 'react-icons/md';

interface FooterAccordionHeaderProps {
  headingId: string;
  headingText: string;
  panelId: string;
  isExpanded: boolean;
  onClick(): void;
}

export default function FooterAccordionHeader({
  headingId,
  headingText,
  panelId,
  isExpanded,
  onClick,
}: FooterAccordionHeaderProps): JSX.Element {
  return (
    <h3>
      <div className="text-12 md:pointer-events-none md:block md:border-0 md:py-4 lg:mt-0 lg:w-auto">
        <button
          className="flex w-full items-center justify-between overflow-hidden pb-2"
          type="button"
          id={headingId}
          aria-expanded={isExpanded}
          aria-controls={panelId}
          onClick={onClick}
        >
          <span className="font-sans text-16 font-semibold text-grey-6">{headingText}</span>
          <MdKeyboardArrowDown
            className={clsx(
              'fill-grey-5 text-24 transition-transform duration-emphasized ease-standard md:hidden',
              { 'rotate-180': isExpanded },
              { 'rotate-0': !isExpanded },
            )}
          />
        </button>
      </div>
    </h3>
  );
}
