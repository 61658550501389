import { HtmlHTMLAttributes } from 'react';
import DekoPayIcon from '~/components/icons/payment/deko';
import MaestroIcon from '~/components/icons/payment/maestro';
import MasterCardIcon from '~/components/icons/payment/mastercard';
import PaypalIcon from '~/components/icons/payment/paypal';
import V12Icon from '~/components/icons/payment/v12.svg';
import VisaIcon from '~/components/icons/payment/visa';

interface PaymentMethodProps extends HtmlHTMLAttributes<HTMLDivElement> {
  visa?: boolean;
  mastercard?: boolean;
  maestro?: boolean;
  paypal?: boolean;
  deko?: boolean;
  v12?: boolean;
}

export default function PaymentMethod({
  visa = true,
  mastercard = true,
  maestro = true,
  paypal = true,
  deko = false,
  v12 = true,
  ...props
}: PaymentMethodProps) {
  return (
    <div {...props}>
      {visa && <VisaIcon />}
      {mastercard && <MasterCardIcon />}
      {maestro && <MaestroIcon />}
      {paypal && <PaypalIcon />}
      {deko && <DekoPayIcon />}
      {v12 && <V12Icon />}
    </div>
  );
}
