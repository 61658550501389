import { useContext } from 'react';
import debounce from 'lodash.debounce';
import { MdClose } from 'react-icons/md';
import { FNCLineItem } from 'shared/types/cart/LineItem';
import CartPricing from '~/components/furniturechoice/cart-pricing';
import QuantitySelector from '~/components/furniturechoice/inputs/quantity-selector';
import ProductMainImage from '~/components/furniturechoice/product-main-image';
import { useCart } from '~/frontastic/hooks';
import ReviewWidgetCart from '~/frontastic/tastics/furniturechoice/product/content-manager/extra-details/review-table/ReviewWidgetCart';
import { ProgressIndicatorContext } from '~/helpers/contexts/furniturechoice/progress-indicator';

interface CartItemProps {
  productData: FNCLineItem;
  locale: string;
}

export default function CartItem({ productData, locale }: CartItemProps): JSX.Element {
  const { executeWithProgress } = useContext(ProgressIndicatorContext);
  const cartApi = useCart();

  const isSwatch = productData.variant?.attributes?.['descriptors_reporting-group2'] == 'SWA';

  function handleRemoveItemClick() {
    if (productData.lineItemId) {
      executeWithProgress(() => cartApi.removeItem(productData.lineItemId as string));
    }
  }

  const handleQuantityUpdate = debounce((newQty: number) => {
    if (newQty > 0) {
      executeWithProgress(() => cartApi.updateItem(productData.lineItemId ?? '', newQty));
    } else {
      handleRemoveItemClick();
    }
  }, 1000);

  return (
    <div data-testid="basket-item" className="relative mb-2 grid grid-cols-7 p-4">
      {isSwatch ? (
        <>
          <div className={'col-span-2'}>
            <a href={productData._url}>
              <ProductMainImage
                className="w-full rounded-full"
                sku={productData.variant.sku}
                colSpanPercent={0.35}
                colSpanPercentLg={0.2}
                sizes="(min-width: 1024px) 110px, 65px"
              />
            </a>
          </div>
          <div className="relative col-span-5 pl-4">
            <div className="absolute h-[20px]"></div>
            <a className="block pr-6 text-14 font-semibold leading-tight" href={productData._url}>
              {productData.name}
            </a>
            <div className="pb-4 pr-6 pt-2 text-12 leading-tight">
              <span className="font-semibold text-green-2">In stock.</span>
              <span> Order now for </span>
              <span className="font-semibold text-green-2">free delivery </span>
              <span className="font-semibold text-grey-5">in 3-5 working days.</span>
            </div>
            <div className="mt-4 flex items-center justify-between">
              {productData.count && (
                <QuantitySelector
                  quantity={productData.count}
                  minValue={0}
                  onCountUpdate={handleQuantityUpdate}
                  isSwatch={isSwatch}
                />
              )}
              <div className="text-right leading-tight">
                <CartPricing item={productData} locale={locale} component="minicart" />
              </div>
            </div>
            <button className="absolute right-0 top-0 text-grey-3" onClick={handleRemoveItemClick}>
              <MdClose size={20} />
            </button>
          </div>
        </>
      ) : (
        <>
          <div className={'col-span-2'}>
            <a href={productData._url}>
              <ProductMainImage
                className="w-full rounded"
                sku={productData.variant.sku}
                colSpanPercent={0.35}
                colSpanPercentLg={0.2}
                sizes="(min-width: 1024px) 220px, 130px"
              />
            </a>
          </div>
          <div className="relative col-span-5 pl-4">
            <div className="">
              <ReviewWidgetCart product={productData} />
            </div>
            <a className="block pr-6 text-14 font-semibold leading-tight" href={productData._url}>
              {productData.name}
            </a>
            <div className="pb-4 pr-6 pt-2 text-12 leading-tight">
              <span className="font-semibold text-green-2">In stock.</span>
              <span> Order now for </span>
              <span className="font-semibold text-green-2">free delivery </span>
              <span className="font-semibold text-grey-5">in 3-5 working days.</span>
            </div>
            <div className="mt-4 flex items-center justify-between">
              {productData.count && (
                <QuantitySelector quantity={productData.count} minValue={0} onCountUpdate={handleQuantityUpdate} />
              )}
              <div className="text-right leading-tight">
                <CartPricing item={productData} locale={locale} component="minicart" />
              </div>
            </div>
            <button className="absolute right-0 top-0 text-grey-3" onClick={handleRemoveItemClick}>
              <MdClose size={20} />
            </button>
          </div>
        </>
      )}
    </div>
  );
}
