'use client';

import { HTMLAttributes, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { MdClose } from 'react-icons/md';
import usePopupFocusEffect from '~/helpers/hooks/furniturechoice/use-popup-focus-effect';
import { useFormat } from '~/helpers/hooks/useFormat';
import DynamicTag from '../dynamic-tag';

interface SideModalProps extends HTMLAttributes<HTMLDialogElement> {
  isOpen: boolean;
  isModal?: boolean;
  title?: string;
  titleAs?: 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  isManagingRootDocScroll?: boolean;
  onModalClose: () => void;
}

export default function SideModal({
  className,
  children,
  isOpen,
  isModal = false,
  title,
  titleAs = 'h2',
  isManagingRootDocScroll = true,
  onModalClose,
  ...props
}: SideModalProps): JSX.Element {
  const dialogRef = useRef<HTMLDialogElement>(null);
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  usePopupFocusEffect({ isPopupVisible: isOpen, manageRootDocScroll: isManagingRootDocScroll });

  useEffect(() => {
    const dialogEl = dialogRef.current as unknown as HTMLDialogElement;

    if (isOpen) {
      if (isModal) {
        dialogEl.showModal();
      } else {
        dialogEl.show();
      }
    } else {
      dialogEl.close();
    }
  }, [isModal, isOpen]);

  return (
    <>
      <dialog
        ref={dialogRef}
        className={clsx(
          'slide-left-initial open:slide-left-enter fixed inset-y-0 right-0 start-[unset] z-600 m-[unset] flex h-full min-h-screen w-full max-w-[calc(100vw-12px)] bg-transparent backdrop:bg-backdrop md:w-1/2 lg:w-[500px]',
          className,
        )}
        inert={!isOpen ? '' : undefined}
        onClick={(event) => event.target === dialogRef.current && !isModal && onModalClose()}
        data-testid="side-modal"
        {...props}
      >
        <button
          className="pointer-events-auto absolute top-0 cursor-pointer rounded rounded-r-none bg-white p-2 text-grey-3"
          onClick={onModalClose}
          aria-label={formatMessage({ id: 'components.side-modal.close' })}
        >
          <MdClose size={24} />
        </button>

        <div className="ml-[40px] flex h-full min-h-screen w-full flex-col overflow-y-auto bg-white md:w-[calc(100%_-_40px)]">
          {title && (
            <DynamicTag className="flex min-h-[40px] items-center px-4 font-sans text-20 font-bold" as={titleAs}>
              {title}
            </DynamicTag>
          )}

          <div className="flex-1 p-4">{children}</div>
        </div>
      </dialog>

      {!isModal && isOpen && (
        <div className={clsx('fixed inset-0 z-[595] bg-backdrop')} hidden={!isOpen} onClick={onModalClose} />
      )}
    </>
  );
}
