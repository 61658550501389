import Group from './group';
import { Group as GroupType } from '../../../../types';

export interface PrimaryPanelProps {
  groups: GroupType[];
}

export default function PrimaryPanel({ groups }: PrimaryPanelProps): JSX.Element {
  const otherGroups = groups.slice(1);

  return (
    <div className="flex flex-col gap-y-5">
      <Group group={groups[0]} isLabelVisible={false} />

      <div className="flex flex-col gap-y-10">
        {otherGroups.map((group) => (
          <Group key={group.label} group={group} isLabelVisible />
        ))}
      </div>
    </div>
  );
}
