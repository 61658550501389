import { Cart } from 'shared/types/cart';
import { FNCLineItem } from 'shared/types/cart/LineItem';
import { UseCartReturn } from '~/frontastic/hooks/useCart/types';
import mapCosts from '~/helpers/utils/mapCosts';

/**
 * Helper to figure out which price to display in an FNC Line Item.
 * Line item discounts, if any, are added back into the price.
 * @param x
 * @returns display price.
 */
export const getLineItemDisplayPrice = (item: FNCLineItem) => {
  if (item.discounts) {
    const discount = item.discounts
      .map((y) => (y.discountedAmount?.centAmount ?? 0) * (item.count ?? 0))
      .reduce((a, b) => a + b, 0);
    return {
      centAmount: (item.totalPrice?.centAmount ?? 0) + discount,
      currencyCode: item.totalPrice?.currencyCode,
      fractionDigits: item.totalPrice?.fractionDigits,
    };
  } else {
    return item.totalPrice;
  }
};

/**
 * Helper to get summary of price amounts in cart
 * @param cart Can use either a UseCartReturn or Cart
 * @returns Object contain the cart's subtotal, discount amount - broken into line item discount amount and cart discount
 */
export function getPriceSummary(cart: UseCartReturn | Cart) {
  const cartData: Cart | undefined = 'data' in cart ? cart.data : (cart as Cart);
  const transaction =
    'transaction' in cart
      ? cart.transaction
      : mapCosts({ reference: 'cart', cart: cart, currency: cartData?.sum?.currencyCode || 'GBP' });

  const lineDiscountAmount =
    cartData && cartData.lineItems
      ? cartData?.lineItems
          .filter((x) => x.discounts && x.discounts.length && x.count)
          .map((x) =>
            x.discounts
              ? x.discounts
                  .map((d) => (d.discountedAmount?.centAmount ?? 0) * (x.count ?? 0))
                  .reduce((acc, amt) => acc + amt, 0)
              : 0,
          )
          .reduce((acc, amt) => acc + amt, 0)
      : 0;

  const subtotalAmount = transaction.subtotal.centAmount - transaction.discount.centAmount + lineDiscountAmount;

  const cartDiscountAmount = subtotalAmount - lineDiscountAmount - (cartData?.sum?.centAmount || subtotalAmount);

  const discountAmount = lineDiscountAmount + cartDiscountAmount;

  const taxAmount = (cartData?.sum?.centAmount || subtotalAmount) - (transaction.tax?.centAmount || 0);

  return { transaction, subtotalAmount, discountAmount, lineDiscountAmount, cartDiscountAmount, taxAmount };
}
