'use client';

import { createContext } from 'react';

interface MinicartContextType {
  isMinicartVisible: boolean;
  openMinicart(): void;
  closeMinicart(): void;
}

const MinicartContext = createContext<MinicartContextType>({
  isMinicartVisible: false,
  openMinicart: () => {},
  closeMinicart: () => {},
});

export default MinicartContext;
