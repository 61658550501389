import { ReactNode, useId, useState } from 'react';
import clsx from 'clsx';
import { MdKeyboardArrowDown } from 'react-icons/md';
import CoFeImage from '~/components/furniturechoice/co-fe-image';
import { ImageProps } from '~/frontastic/lib/image';

export interface AccordionItemProps {
  children: ReactNode;
  label: string;
  image?: ImageProps;
  defaultIsExpanded: boolean;
  isExpandable?: boolean;
}

export default function AccordionItem({
  children,
  label,
  image,
  defaultIsExpanded,
  isExpandable = true,
}: AccordionItemProps): JSX.Element {
  const [isExpanded, setIsExpanded] = useState<boolean>(defaultIsExpanded);
  const buttonId = useId();
  const panelId = useId();

  return (
    <div className="flex flex-col gap-y-6">
      <button
        className="flex h-full w-full items-center gap-x-4 px-6 py-1 text-left"
        data-testid="cta-button"
        type="button"
        id={buttonId}
        aria-expanded={isExpanded}
        aria-controls={panelId}
        onClick={() => setIsExpanded(isExpandable ? !isExpanded : defaultIsExpanded)}
      >
        <div className="flex h-11 w-11 items-center justify-center overflow-hidden rounded-full bg-grey-2">
          <CoFeImage className="aspect-[44/36]" asset={image?.media} aspectRatio={44 / 36} sizes="44px" />
        </div>

        <p className="flex-1 text-18 font-bold">{label}</p>
        <MdKeyboardArrowDown
          className={clsx('text-24 transition-transform duration-standard ease-standard', { 'rotate-180': isExpanded })}
          data-testid="arrow-icon"
        />
      </button>

      <div className="pb-1 pl-[46px] pr-6" role="region" id={panelId} aria-labelledby={buttonId} hidden={!isExpanded}>
        <div className="border-l border-solid border-grey-3 pl-[22px]">{children}</div>
      </div>
    </div>
  );
}
