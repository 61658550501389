import { ImgHTMLAttributes } from 'react';
import clsx from 'clsx';
import buildImageSizes from '~/utils/build-image-sizes';

interface ContentfulImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  src?: string;
  colSpanPercent?: number;
  colSpanPercentSm?: number;
  colSpanPercentMd?: number;
  colSpanPercentLg?: number;
  colSpanPercentXl?: number;
  colSpanPercent2Xl?: number;
  aspectRatio?: number;
  aspectRatioLg?: number;
}

function buildSrcSet(src: string, aspectRatio?: number, aspectRatioLg?: number): string {
  // Source: concatenation of deviceSizes and imageSizes of Next.js
  // https://nextjs.org/docs/pages/api-reference/components/image
  //
  // In addition, there are some intermediate steps as suggested from ChatGPT
  const widths = [
    16, 32, 48, 64, 96, 128, 256, 384, 640, 750, 828, 1080, 1200, 1440, 1600, 1920, 2048, 2560, 2880, 3200, 3440, 3840,
  ];

  return widths
    .map((width) => {
      const lgBreakpoint = 1024;

      if (width < lgBreakpoint && aspectRatio) {
        const height = parseInt((width / aspectRatio).toString());

        return `${src}?w=${width}&h=${height}&fm=webp&fit=fill ${width}w`;
      }

      if (width >= lgBreakpoint && aspectRatioLg) {
        const height = parseInt((width / aspectRatioLg).toString());

        return `${src}?w=${width}&h=${height}&fm=webp&fit=fill ${width}w`;
      }

      return `${src}?w=${width}&fm=webp ${width}w`;
    })
    .join(', ');
}

/**
 * This component builds out an <img> sourcing from Contentful with built-in support
 * for providing an image size estimate to download depending on the breakpoint. This is
 * useful so that we only download what is approximately needed. For example, a thumbnail
 * image of 150x150 doesn't need to download an image who's original size is 3000x3000.
 *
 * Column span percent parameters are provided per breakpoint. This will represent how much
 * an image will occupy in terms of width from within a .container class. For example,
 * if an image will occupy approximately 50% of the container, then you set the corresponding
 * column span percent to 0.5.
 *
 * If the image isn't in a .container class and will fill the whole edge-to-edge of the screen
 * for all devices, then you can just pass-in `sizes="100vw"` as a parameter. A usual example
 * for this scenario are Hero Banners. This is the same case for images that have the same
 * size regardless of the device size. For example, you can just pass-in `sizes="150x"` for
 * images that will be 150px on all device type.
 */
export default function ContentfulImage({
  className,
  src,
  alt,
  loading = 'lazy',
  colSpanPercent = 1,
  colSpanPercentSm,
  colSpanPercentMd,
  colSpanPercentLg,
  colSpanPercentXl,
  colSpanPercent2Xl,
  aspectRatio,
  aspectRatioLg,
  ...props
}: ContentfulImageProps): JSX.Element {
  if (src) {
    const sizes = buildImageSizes({
      colSpanPercent,
      colSpanPercentSm,
      colSpanPercentMd,
      colSpanPercentLg,
      colSpanPercentXl,
      colSpanPercent2Xl,
    });

    return (
      // Don't use next/image because it has no feature for cropping height
      // eslint-disable-next-line @next/next/no-img-element
      <img
        data-testid="contentful-image"
        className={className}
        // Needs to have the following order: sizes, srcSet, src
        // See: https://bugs.webkit.org/show_bug.cgi?id=190031
        sizes={sizes}
        srcSet={buildSrcSet(src, aspectRatio, aspectRatioLg || aspectRatio)}
        src={src}
        alt={alt || ''}
        loading={loading}
        {...props}
      />
    );
  }

  return <div className={clsx('aspect-square w-full animate-pulse bg-skeleton', className)} {...props} />;
}
