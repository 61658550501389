import clsx from 'clsx';
import Image, { ImageProps } from '~/frontastic/lib/image';
import buildImageSizes from '~/utils/build-image-sizes';

interface CoFeImageProps extends Omit<ImageProps, 'media'> {
  asset: ImageProps['media'];
  colSpanPercent?: number;
  colSpanPercentSm?: number;
  colSpanPercentMd?: number;
  colSpanPercentLg?: number;
  colSpanPercentXl?: number;
  colSpanPercent2Xl?: number;
  aspectRatio?: number;
  aspectRatioLg?: number;
}

/**
 * This component builds out an <img> sourcing from CoFe with built-in support
 * for providing an image size estimate to download depending on the breakpoint. This is
 * useful so that we only download what is approximately needed. For example, a thumbnail
 * image of 150x150 doesn't need to download an image who's original size is 3000x3000.
 *
 * Column span percent parameters are provided per breakpoint. This will represent how much
 * an image will occupy in terms of width from within a .container class. For example,
 * if an image will occupy approximately 50% of the container, then you set the corresponding
 * column span percent to 0.5.
 *
 * If the image isn't in a .container class and will fill the whole edge-to-edge of the screen
 * for all devices, then you can just pass-in `sizes="100vw"` as a parameter. A usual example
 * for this scenario are Hero Banners. This is the same case for images that have the same
 * size regardless of the device size. For example, you can just pass-in `sizes="150x"` for
 * images that will be 150px on all device type.
 */
export default function CoFeImage({
  className,
  asset,
  alt,
  title,
  colSpanPercent = 1,
  colSpanPercentSm,
  colSpanPercentMd,
  colSpanPercentLg,
  colSpanPercentXl,
  colSpanPercent2Xl,
  aspectRatio,
  aspectRatioLg,
  ...props
}: CoFeImageProps): JSX.Element {
  if (asset) {
    const sizes = buildImageSizes({
      colSpanPercent,
      colSpanPercentSm,
      colSpanPercentMd,
      colSpanPercentLg,
      colSpanPercentXl,
      colSpanPercent2Xl,
    });

    return (
      <Image
        className={className}
        data-testid="co-fe-image"
        media={asset}
        sizes={sizes}
        alt={alt || title || ''}
        title={title}
        ratio={aspectRatio?.toString()}
        ratioLg={aspectRatioLg?.toString()}
        {...props}
      />
    );
  }

  return <div className={clsx('aspect-square w-full animate-pulse bg-skeleton', className)} {...props} />;
}
