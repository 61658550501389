'use client';

import PaymentMethod from '~/components/furniturechoice/payment-methods';
import TrustpilotWidget from '~/components/furniturechoice/trustpilot-widget';
import { TasticProps } from '~/frontastic/tastics/types';
import { useFormat } from '~/helpers/hooks/useFormat';
import FooterNavigation, { SiteFooterNavGroup } from './footer-navigation';

export interface SiteFooterProps {
  navigationGroups: SiteFooterNavGroup[];
}

export default function SiteFooter({ data }: TasticProps<SiteFooterProps>) {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  return (
    <div className="container">
      <div className="mb-8 grid grid-cols-4 gap-4 border-t border-grey-5 md:mb-0 lg:mb-8 lg:gap-0">
        <FooterNavigation data={data.navigationGroups} />
        <div className="col-span-4 grid gap-y-4 py-4 sm:flex md:py-4 lg:col-span-1 lg:grid">
          <div className="w-full sm:w-1/2 md:mt-4 lg:w-full">
            <TrustpilotWidget templateId="53aa8807dec7e10d38f59f32" className="lg:w-[250px]" />
          </div>
          <div className="w-full sm:w-1/2 lg:w-full">
            <div className="mb-1 text-14 leading-6 text-grey-4 sm:text-center lg:text-left">
              {formatMessage({ id: 'tastics.core.site-footer.payment_options', defaultMessage: 'Payment options' })}:
            </div>
            <PaymentMethod className="flex gap-2 sm:justify-center lg:justify-start" />
          </div>
        </div>
      </div>
    </div>
  );
}
