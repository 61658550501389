'use client';

import { Fragment, useContext } from 'react';
import { useParams } from 'next/navigation';
import clsx from 'clsx';
import { MdArrowBackIos } from 'react-icons/md';
import CartTotal from '~/components/furniturechoice/cart-pricing/CartTotal';
import FNCLinkButton from '~/components/furniturechoice/fnc/fnc-link-button';
import PaymentMethod from '~/components/furniturechoice/payment-methods';
import SideModal from '~/components/furniturechoice/side-modal';
import { useCart } from '~/frontastic/hooks';
import { TasticProps } from '~/frontastic/tastics/types';
import { MinicartContext } from '~/helpers/contexts/furniturechoice/minicart';
import usePopupFocusEffect from '~/helpers/hooks/furniturechoice/use-popup-focus-effect';
import { useFormat } from '~/helpers/hooks/useFormat';
import CartItem from './cart-item';

interface MinicartProps {
  placeholder?: string;
}

export default function Minicart({ params }: TasticProps<MinicartProps>) {
  const cart = useCart();
  const { isMinicartVisible, closeMinicart } = useContext(MinicartContext);
  const { locale } = useParams();
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  usePopupFocusEffect({
    isPopupVisible: isMinicartVisible,
    focusRef: '#minicart-go-to-basket-cta',
    manageRootDocScroll: true,
  });

  return (
    <SideModal isOpen={isMinicartVisible} onModalClose={closeMinicart} data-testid="minicart" {...params}>
      <section className="flex h-full flex-col">
        <div className="flex flex-1 flex-col overflow-x-auto">
          <h2 className="border-b py-4 text-center font-sans font-semibold leading-none">
            {formatMessage({ id: 'tastics.core.minicart.your_shopping_cart' })} <small>({cart.totalItems})</small>
          </h2>
          <div className="mb-auto flex flex-col">
            {!cart.isEmpty ? (
              cart.data?.lineItems?.map((lineItem) => (
                <Fragment key={(lineItem.lineItemId ?? '') + (lineItem.count ?? '0')}>
                  <CartItem locale={locale} productData={lineItem} />
                </Fragment>
              ))
            ) : (
              <span className="mt-3 text-center text-grey-5">
                {formatMessage({ id: 'tastics.core.minicart.your_shopping_basket_is_empty' })}
              </span>
            )}
          </div>
        </div>

        <div className="flex items-center justify-between rounded bg-grey-1 px-4 py-2 align-middle">
          <span className="font-bold">{formatMessage({ id: 'tastics.core.minicart.total' })}</span>
          <div className="py-2 text-right font-semibold leading-tight">
            <CartTotal
              className={clsx('inline text-20 font-bold', {
                'text-red-1': cart.data?.lineItems?.find((i) => i.discountedPrice),
              })}
              cart={cart.data}
              locale={params.locale}
            />
            <span className="block text-right text-12 font-normal">
              {formatMessage({ id: 'tastics.core.minicart.including_vat' })}
            </span>
          </div>
        </div>

        <FNCLinkButton href="/basket" className="surface-green-2 my-4 rounded-sm" id="minicart-go-to-basket-cta">
          {formatMessage({ id: 'tastics.core.minicart.go_to_basket' })}
        </FNCLinkButton>

        <a
          href="#"
          onClick={closeMinicart}
          className="mx-auto flex items-center text-center text-14 text-salmon-4 hover:underline"
        >
          <MdArrowBackIos />
          {formatMessage({ id: 'tastics.core.minicart.continue_shopping' })}
        </a>
        <PaymentMethod className="mx-auto mt-6 flex justify-center gap-2" />
      </section>
    </SideModal>
  );
}
