import Link from '~/components/furniturechoice/link';
import { SiteFooterNavGroup } from './';
import { FooterAccordionContent } from './footer-accordion';

export default function formatNavGroups(navItem: SiteFooterNavGroup): FooterAccordionContent {
  const item = (
    <ul className="w-full text-16 font-normal leading-8 text-grey-5 md:block md:w-full md:text-13 md:leading-5">
      {navItem.links.map((link, index) => (
        <li key={index}>
          <Link
            className="py-1 md:py-0"
            reference={link.reference}
            //ga4_event_name="navigation_footer"
            //ga4_event_param_1_name="element"
            //ga4_event_param_1_value={link.name}
          >
            {link.name}
          </Link>
        </li>
      ))}
    </ul>
  );

  return {
    headingText: navItem.name,
    panel: item,
    isExpanded: false,
  };
}
