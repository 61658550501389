'use client';

import { useState } from 'react';
import clsx from 'clsx';
import { MdMenu, MdOutlineShoppingBasket, MdPersonOutline } from 'react-icons/md';
import CoFeImage from '~/components/furniturechoice/co-fe-image';
import HeartIcon from '~/components/furniturechoice/icons/heart';
import Link from '~/components/furniturechoice/link';
import { ImageProps } from '~/frontastic/lib/image';
import { TasticProps } from '~/frontastic/tastics/types';
import { useFormat } from '~/helpers/hooks/useFormat';
import DesktopMegamenu from './desktop-megamenu';
import MobileMegamenu from './mobile-megamenu';
import SearchField from './search-field';
import { Footnote, Menu } from './types';

export interface SiteHeaderProps {
  logo: ImageProps;
  menus: Menu[];
  footnote: [Footnote];
}

export default function SiteHeader({ data: { logo, menus, footnote } }: TasticProps<SiteHeaderProps>): JSX.Element {
  const [isMobileMegamenuExpanded, setIsMobileMegamenuExpanded] = useState<boolean>(false);
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  return (
    <header className="relative z-100">
      <div className="surface-white">
        <div
          className={clsx(
            'container grid grid-cols-2 gap-y-4 py-6',
            '[grid-template-areas:"logo_actions""search_search"]',
            'lg:grid-cols-[1fr_430px_max-content] lg:items-center lg:gap-x-5 lg:gap-y-6 lg:pb-3 lg:[grid-template-areas:"logo_search_actions""desktop-nav_desktop-nav_desktop-nav"]',
          )}
        >
          <Link className="[grid-area:logo]" href="/">
            <CoFeImage
              className="h-auto w-[155px] lg:w-60"
              asset={logo.media}
              alt={logo.alt}
              aspectRatio={155 / 40}
              colSpanPercent={0.5}
              colSpanPercentLg={0.2}
            />
          </Link>

          <div className="flex items-end justify-end gap-x-3 [grid-area:actions] lg:gap-x-4">
            <Link className="hidden lg:block" href="/sign-in">
              <MdPersonOutline className="text-30" />
              <span className="sr-only">{formatMessage({ id: 'tastics.core.site-header.go_to_sign_in' })}</span>
            </Link>

            <Link href="/wishlist">
              <HeartIcon className="text-30" />
              <span className="sr-only">{formatMessage({ id: 'tastics.core.site-header.go_to_wishlist' })}</span>
            </Link>

            <Link href="/basket">
              <MdOutlineShoppingBasket className="text-30" />
              <span className="sr-only">{formatMessage({ id: 'tastics.core.site-header.go_to_basket' })}</span>
            </Link>

            <button className="lg:hidden" onClick={() => setIsMobileMegamenuExpanded(true)}>
              <MdMenu className="text-30" />
              <span className="sr-only">{formatMessage({ id: 'tastics.core.site-header.open_megamenu' })}</span>
            </button>
          </div>

          <MobileMegamenu
            menus={menus}
            footnote={footnote[0]}
            isExpanded={isMobileMegamenuExpanded}
            onCloseClick={() => setIsMobileMegamenuExpanded(false)}
          />

          <SearchField />
          <DesktopMegamenu menus={menus} footnote={footnote[0]} />
        </div>
      </div>
    </header>
  );
}
