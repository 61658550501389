import clsx from 'clsx';

interface FooterAccordionPanelProps {
  panelId: string;
  panelContent: JSX.Element | string;
  headingId: string;
  isExpanded: boolean;
}

export default function FooterAccordionPanel({
  panelId,
  panelContent,
  headingId,
  isExpanded,
}: FooterAccordionPanelProps): JSX.Element {
  return (
    <div
      className={clsx(
        'grid overflow-hidden border-b border-grey-3 transition-all duration-300 ease-in-out',
        'md:grid-rows-[1fr] md:border-0 md:pb-4 md:transition-none',
        {
          'grid-rows-[0fr]': !isExpanded,
          'grid-rows-[1fr] pb-4': isExpanded,
        },
      )}
      id={panelId}
      aria-labelledby={headingId}
    >
      <div className="overflow-hidden">{panelContent}</div>
    </div>
  );
}
