import { FNCReviewRatingStatistics } from 'shared/types/furniturechoice/product';

export class ReviewSummaryHelper {
  static starImage = '/images/star-bg.png';

  static getAverageRating(stats: FNCReviewRatingStatistics) {
    return stats.averageRating.toFixed(1);
  }

  static getReviewCount(stats: FNCReviewRatingStatistics) {
    return {
      id: 'tastics.review.x_reviews',
      values: { count: stats.count.toString(), s: stats.count == 1 ? '' : 's' },
    };
  }
}
