import { useState } from 'react';
import usePopupFocusEffect from '~/helpers/hooks/furniturechoice/use-popup-focus-effect';
import Menu from './menu';
import { Footnote as FootnoteType, Menu as MenuType } from '../types';

export interface DesktopMegamenuProps {
  menus: MenuType[];
  footnote: FootnoteType;
}

export default function DesktopMegamenu({ menus, footnote }: DesktopMegamenuProps): JSX.Element {
  const [expandedColumn, setExpandedColumn] = useState<number | null>(null);

  usePopupFocusEffect({ isPopupVisible: expandedColumn !== null, manageRootDocScroll: true });

  function handleMenuClick(index: number): void {
    if (expandedColumn === index) {
      setExpandedColumn(null);
    } else {
      setExpandedColumn(index);
    }
  }

  return (
    <nav className="hidden border-b border-solid border-grey-7 [grid-area:desktop-nav] lg:block">
      <ul className="flex items-center justify-between px-3 py-1">
        {menus.map((menu, index) => (
          <Menu
            key={menu.label}
            menu={menu}
            footnote={footnote}
            isExpanded={expandedColumn === index}
            onMenuClick={() => handleMenuClick(index)}
            onBackdropClick={() => setExpandedColumn(null)}
          />
        ))}
      </ul>
    </nav>
  );
}
