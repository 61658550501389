import { Reference } from '~/types/reference';
import FooterAccordion from './footer-accordion';
import formatNavGroups from './formatNavGroups';

export interface SiteFooterNavGroup {
  name: string;
  links: {
    name: string;
    reference: Reference;
  }[];
}

interface FooterNavigationProps {
  data: SiteFooterNavGroup[];
}

export default function FooterNavigation({ data }: FooterNavigationProps): JSX.Element {
  const accordionContent = data.map((d) => formatNavGroups(d));
  return (
    <>
      <FooterAccordion
        className="col-span-4 pt-4 md:flex md:flex-row lg:col-span-3 lg:gap-x-10 lg:pl-4 lg:pt-0"
        contents={accordionContent}
      />
    </>
  );
}
